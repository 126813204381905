import React from 'react';
import Homepage from '../gatsby-theme-carbon/components/Homepage';
import { ArticleCard, Column, Row } from 'gatsby-theme-carbon';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import WarningAlt32 from '@carbon/icons-react/lib/warning--alt/32';
import CheckmarkOutline32 from '@carbon/icons-react/lib/checkmark--outline/32';
import CircleDash32 from '@carbon/icons-react/lib/circle-dash/32';
import { WarningAlt24 } from '@carbon/icons-react';
import CheckmarkOutline24 from '@carbon/icons-react/lib/checkmark--outline/24';
import CircleDash24 from '@carbon/icons-react/lib/circle-dash/24';

const buildTitle = (shroom) =>
  Array.isArray(shroom.names) && shroom.names.length
    ? `${shroom.title} (${(shroom.names || []).join(', ')})`
    : shroom.title;
export const buildIcon = (edibility, size = 32) => {
  if (edibility === 'Tòxic') {
    return size === 32 ? (
      <WarningAlt32 className="bx--icon bx--icon--toxic" />
    ) : (
      <WarningAlt24 className="bx--icon bx--icon--toxic" />
    );
  }
  if (edibility === 'Comestible') {
    return size === 32 ? (
      <CheckmarkOutline32 className="bx--icon bx--icon--edible" />
    ) : (
      <CheckmarkOutline24 className="bx--icon bx--icon--edible" />
    );
  }
  return size === 32 ? (
    <CircleDash32 className="bx--icon bx--icon--indifferent" />
  ) : (
    <CircleDash24 className="bx--icon bx--icon--indifferent" />
  );
};
const Index = ({
  data: {
    allMdx: { edges },
  },
}) => (
  <Homepage pageContext={{}}>
    <Row>
      {edges
        .filter((i) => i)
        .filter((i) => i.node.frontmatter.imagePath)
        .map(({ node: { frontmatter: shroom, slug } }) => (
          <Column
            colMd={4}
            colLg={3}
            key={shroom.id}
            noGutterSmLeft
            style={{ marginBottom: '1rem' }}
            id={(shroom.title || '').replace(/ /, '-').toLowerCase()}
          >
            <ArticleCard
              title={buildTitle(shroom)}
              author={shroom.notes}
              readTime={(shroom.habitats || []).join(', ')}
              href={slug}
              color="dark"
            >
              {buildIcon(shroom.edibility)}
              <Img fluid={shroom.imagePath.childImageSharp.fluid} />
            </ArticleCard>
          </Column>
        ))}
    </Row>
  </Homepage>
);
export default Index;
export const pageQuery = graphql`
  query IndexQuery {
    allMdx(
      sort: { fields: [frontmatter___gender], order: ASC }
      filter: {
        frontmatter: { imagePath: { internal: { type: { eq: "File" } } } }
      }
    ) {
      edges {
        node {
          slug
          frontmatter {
            names
            title
            family
            gender
            notes
            locations
            habitats
            synonyms
            similar
            edibility
            imagePath {
              childImageSharp {
                fluid(
                  toFormat: WEBP
                  maxWidth: 300
                  maxHeight: 200
                  quality: 60
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
          }
        }
      }
    }
  }
`;
