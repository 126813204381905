import React from 'react';
import PropTypes from 'prop-types';

import { useMetadata } from 'gatsby-theme-carbon/src/util/hooks';
import Header from './Header';
import Meta from 'gatsby-theme-carbon/src/components/Meta';
import Container from 'gatsby-theme-carbon/src/components/Container';
import Switcher from 'gatsby-theme-carbon/src/components/Switcher';

const Homepage = ({ children, pageContext }) => {
  const { frontmatter = {}, titleType } = pageContext;
  const { title, description, keywords } = frontmatter;
  const { homepageTheme, isSwitcherEnabled } = useMetadata();

  return (
    <>
      <Meta
        titleType={titleType}
        pageTitle={title}
        pageDescription={description}
        pageKeywords={keywords}
      />
      <Header isSwitcherEnabled={isSwitcherEnabled} />
      {isSwitcherEnabled && <Switcher />}
      <Container homepage={true} theme={homepageTheme}>
        {children}
      </Container>
    </>
  );
};
Homepage.propTypes = {
  children: PropTypes.element,
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.object,
    titleType: PropTypes.string,
  }),
};

export default Homepage;
